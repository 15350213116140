import { graphql, Link } from "gatsby"
import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import Head from "../components/head"
import blogStyles from "./blog.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      description
      body {
        json
      }
    }
  }
`

const getRandArrayIndex = length => Math.floor(Math.random() * length)

const getPsuedoRandomSlugURLs = slugs => {
  if (slugs.length < 2) return null
  if (slugs.length === 2) return slugs
  const randIntA = getRandArrayIndex(slugs.length)
  const rest = [...slugs.slice(0, randIntA), ...slugs.slice(randIntA + 1)]
  return [slugs[randIntA], rest[getRandArrayIndex(rest.length)]]
}

const Blog = props => {
  const {
    data: { contentfulBlogPost },
    pageContext: { dataForFilteredPosts }
  } = props

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const enUSKey = "en-US"
        const { title, file } = node.data.target.fields
        return <img alt={title[enUSKey]} src={file[enUSKey].url} />
      }
    }
  }

  const navItemData = getPsuedoRandomSlugURLs(dataForFilteredPosts)
  const navItems =
    navItemData &&
    navItemData.map(({ slugURL, title, publishedDate }) => (
      <div key={slugURL} className={blogStyles.navPostLinkContainer}>
        <Link to={slugURL}>
          <div>{title}</div>
          <div>{publishedDate}</div>
        </Link>
      </div>
    ))

  const {
    title,
    publishedDate,
    description,
    body: { json }
  } = contentfulBlogPost

  return (
    <Layout>
      <Head title={title} description={description} />
      <h2>{title}</h2>
      <p>{publishedDate}</p>
      {documentToReactComponents(json, options)}

      {navItems ? (
        <div className={blogStyles.navPostMainContainer}>
          <Link to="/blog/" className={blogStyles.navPostBlogLink}>
            <div>Blog Menu</div>
          </Link>
          {navItems}
        </div>
      ) : (
        <Link to="/blog/">Blog Menu</Link>
      )}
    </Layout>
  )
}

export default Blog
